<template>
  <IndexView>
    <VHeader>
      <template #avatar>
        <VImage
          :name="name"
          :src="getAvatarURL(data?.avatar?.view_path)"
          :width="12"
          :height="12"
          class="items-center justify-center"
        />
      </template>

      <template #title>
        {{ name }}
      </template>

      <template #subtitle>@{{ data?.username }}</template>

      <template #status>
        <VChip
          :text="$t(`app.${data?.status}`)"
          :class="getStatusColor(data?.status)"
        />
      </template>

      <template #tabs>
        <div class="flex justify-between">
          <VTabs
            :current-tab="currentTab"
            :tabs="tabs"
            @click="onClickRedirect"
          />
        </div>
      </template>
    </VHeader>

    <router-view :id="id" :key="id" :resource="data" />
  </IndexView>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
// Composables
import useTabs from "@/composables/useTabs";
import useDisplay from "@/composables/useDisplay";
import useReadOne from "@/composables/useReadOne";
import useColor from "@/composables/useColor";
// Components
import IndexView from "./IndexView";
import VHeader from "@/components/VHeader";
import VTabs from "@/components/VTabs";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    IndexView,
    VHeader,
    VTabs,
    VImage,
    VChip
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const route = useRoute();

    // Constants
    const baseRoute = "teaching-instructors";
    const TABS = {
      OVERVIEW: "overview",
      LESSONS: "lessons",
      ATTESTATIONS: "attestations",
      EVALUATIONS: "evaluations",
      TRAININGS: "trainings",
      COLLECTIONS: "collections"
    };

    // Composables
    const { buildTab } = useTabs();
    const { getStatusColor } = useColor();
    const { data, isLoading, read } = useReadOne();
    const { getAvatarURL } = useDisplay();

    // Computed
    const currentTab = computed(() => {
      switch (route.name) {
        case `${baseRoute}-overview`:
          return "overview";
        case `${baseRoute}-lessons`:
          return "lessons";
        case `${baseRoute}-attestations`:
          return "attestations";
        case `${baseRoute}-evaluations`:
          return "evaluations";
        case `${baseRoute}-trainings`:
          return "trainings";
        case `${baseRoute}-collections`:
          return "collections";
        default:
          return "";
      }
    });
    const name = computed(() => {
      return `${data.value?.firstname} ${data.value?.lastname}`;
    });

    const tabs = computed(() => {
      return Object.values(TABS).map(tab => buildTab({ name: tab }));
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.instructors.read",
        pathParams: { id: props.id }
      });
    };

    const onClickRedirect = tab => {
      router.push({
        name: `${baseRoute}-${tab}`,
        params: {
          id: props.id
        }
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      name,
      onClickRedirect,
      tabs,
      currentTab,
      // useReadOne
      data,
      isLoading,
      // useColor
      getStatusColor,
      // useDisplay
      getAvatarURL
    };
  }
};
</script>
